import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";
import { store } from "@/app/store";

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/app/home");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/app/signin?redirectUrl=" + to.path);
};

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/pages/landings/main-page.vue"),
      meta: {
        title:
          "Stormbpmn: инструмент для совместной работы с бизнес-процессами в BPMN",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Stormbpmn: единое место правды о том, как работает ваша компания. Узнайте об основных преимуществах.",
          },
          {
            property: "og:description",
            content:
              "Stormbpmn: единое место правды о том, как работает ваша компания. Узнайте об основных преимуществах.",
          },
        ],
      },
    },
    {
      path: "/404",
      name: "NotFoundPage",
      component: () => import("@/pages/landings/NotFound"),
      meta: {
        title: "404: Ничего не найдено  :(",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content: "Страница 404 - ничего не найдено :(",
          },
          {
            property: "og:description",
            content: "Страница 404 - ничего не найдено :(",
          },
        ],
      },
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/bpmn/elements",
      name: "elements",
      component: () => import("@/pages/help/ElementsView"),
      meta: {
        title: "BPMN Примеры элементов",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание всех элементов BPMN с хорошими и плохими примерами.",
          },
          {
            property: "og:description",
            content:
              "Подробное описание всех элементов BPMN с хорошими и плохими примерами.",
          },
        ],
      },
    },
    {
      path: "/license",
      name: "license",
      component: () => import("@/pages/license/TheLicense.vue"),
      meta: {
        title: "Лицензия",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content: "Условия использования сервиса stormbpmn.",
          },
          {
            property: "og:description",
            content: "Условия использования сервиса stormbpmn.",
          },
        ],
      },
    },
    {
      path: "/enterprise",
      name: "enterprise",
      component: () => import("@/pages/landings/Enterprise.vue"),
      meta: {
        title: "Stormbpmn для крупных предприятий",
        hideTitle: true,

        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание использования stormbpmn для крупных предприятий",
          },
          {
            property: "og:description",
            content:
              "Подробное описание использования stormbpmn для крупных предприятий",
          },
        ],
      },
    },
    {
      path: "/modeling-in-bpmn",
      name: "modeling-in-bpmn",
      component: () => import("@/pages/landings/ModelingBPMN.vue"),
      meta: {
        title: "Моделирование процессов в BPMN",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание того, как можно моделить бизнес-процессы в нотации BPMN с использованием stormbpmn.",
          },
          {
            property: "og:description",
            content:
              "Подробное описание того, как можно моделить бизнес-процессы в нотации BPMN с использованием stormbpmn.",
          },
        ],
      },
    },
    {
      path: "/manage-camunda",
      name: "manage-camunda",
      component: () => import("@/pages/landings/ManageCamunda.vue"),
      meta: {
        title: "Управляйте Camunda серверами",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание использования stormbpmn для управления серверами и бизнес-процессами с использованием Camunda",
          },
          {
            property: "og:description",
            content:
              "Подробное описание использования stormbpmn для управления серверами и бизнес-процессами с использованием Camunda",
          },
        ],
      },
    },
    {
      path: "/architecture-bpmn",
      name: "arcitecture-and-bpmn",
      component: () => import("@/pages/landings/ArchitectureBPMN.vue"),
      meta: {
        title: "Архитектура и BPMN",
        hideTitle: true,
      },
      metaTags: [
        {
          name: "description",
          content:
            "Информация о том, как stormbpmn позволяет моделировать архитектуру предприятия.",
        },
        {
          property: "og:description",
          content:
            "Информация о том, как stormbpmn позволяет моделировать архитектуру предприятия.",
        },
      ],
    },
    {
      path: "/smb",
      name: "smb",
      component: () => import("@/pages/landings/SMB.vue"),
      meta: {
        title: "Stormbpmn для команд",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Рассказ о преимуществах stormbpmn для небольших компаний.",
          },
          {
            property: "og:description",
            content:
              "Рассказ о преимуществах stormbpmn для небольших компаний.",
          },
        ],
      },
    },
    {
      path: "/personal",
      name: "personal",
      component: () => import("@/pages/landings/PersonalUse.vue"),
      meta: {
        title: "Stormbpmn для персонального использования",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Страница о преимуществах сервиса stormbpmn для персонального использования.",
          },
          {
            property: "og:description",
            content:
              "Страница о преимуществах сервиса stormbpmn для персонального использования.",
          },
        ],
      },
    },
    {
      path: "/nonprofits",
      name: "nonrpofits",
      component: () => import("@/pages/landings/Nonprofits.vue"),
      meta: {
        title: "Stormbpmn для некомерческих организаций",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Описание использования  и условий stormbpmn для некомерческих организаций",
          },
          {
            property: "og:description",
            content:
              "Описание использования  и условий stormbpmn для некомерческих организаций",
          },
        ],
      },
    },
    {
      path: "/education",
      name: "education",
      component: () => import("@/pages/landings/Education.vue"),
      meta: {
        title: "Stormbpmn для образовательных организаций",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Страница о том, как можно использовать stormbpmn для образовательных учреждений и образховательного процесса",
          },
          {
            property: "og:description",
            content:
              "Страница о том, как можно использовать stormbpmn для образовательных учреждений и образховательного процесса",
          },
        ],
      },
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () => import("@/pages/landings/ThePricingTableView"),
      meta: {
        title: "Stormbpmn тарифы",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content: "Подробное описание тарифных планов stormbpmn",
          },
          {
            property: "og:description",
            content: "Подробное описание тарифных планов stormbpmn",
          },
        ],
      },
    },
    {
      path: "/compare/drawio",
      name: "compare-drawio",
      component: () => import("@/pages/landings/CompareDrawIO"),
      meta: {
        title: "Сравнение Stormbpmn и drawIO",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание сервиса Stormbpmn и drawio, с плюсами и минусами",
          },
          {
            property: "og:description",
            content:
              "Подробное описание сервиса Stormbpmn и drawio, с плюсами и минусами",
          },
        ],
      },
    },
    {
      path: "/contact-sales",
      name: "contact-sales",
      component: () => import("@/pages/landings/ContactSales"),
      meta: {
        title: "Свяжитесь с продажами",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content: "Страница для свяжи с отделом продаж stormbpmn",
          },
          {
            property: "og:description",
            content: "Страница для свяжи с отделом продаж stormbpmn",
          },
        ],
      },
    },
    {
      path: "/compare/bizagi",
      name: "compare-bizagi",
      component: () => import("@/pages/landings/CompareBizagi"),
      meta: {
        title: "Сравнение Stormbpmn и Bizagi Modeler",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание сервиса Stormbpmn и Bizagi Modeler, с плюсами и минусами",
          },
          {
            property: "og:description",
            content:
              "Подробное описание сервиса Stormbpmn и Bizagi Modeler, с плюсами и минусами",
          },
        ],
      },
    },
    {
      path: "/compare/camunda-modeler",
      name: "compare-camunda-modeler",
      component: () => import("@/pages/landings/CompareCamundaModeler.vue"),
      meta: {
        title: "Сравнение Stormbpmn и Camunda Modeler",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание сервиса Stormbpmn и Camunda modeler, с плюсами и минусами",
          },
          {
            property: "og:description",
            content:
              "Подробное описание сервиса Stormbpmn и Camunda modeler, с плюсами и минусами",
          },
        ],
      },
    },
    {
      path: "/compare/miro",
      name: "compare-miro",
      component: () => import("@/pages/landings/CompareMiro.vue"),
      meta: {
        title: "Сравнение Stormbpmn и Miro",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное описание сервиса Stormbpmn и Miro, с плюсами и минусами",
          },
          {
            property: "og:description",
            content:
              "Подробное описание сервиса Stormbpmn и Miro, с плюсами и минусами",
          },
        ],
      },
    },
    {
      path: "/compare",
      name: "compare",
      component: () => import("@/pages/compare/CompareView"),
      meta: {
        title: "Stormbpmn сравнение с конкурентами ",
        hideTitle: true,
        metaTags: [
          {
            name: "description",
            content:
              "Подробное сравнение сервиса Stormbpmn и его конкурентов, с плюсами и минусами",
          },
          {
            property: "og:description",
            content:
              "Подробное сравнение сервиса Stormbpmn и его конкурентов, с плюсами и минусами",
          },
        ],
      },
    },
    {
      path: "/courses/payment",
      name: "payCourses",
      component: () => import("@/pages/courses/CoursesView.vue"),
      meta: {
        title: "BPMN Courses",
        hideTitle: true,
      },
    },
    {
      path: "/courses/cert",
      name: "certGenerator",
      component: () => import("@/pages/courses/CertView.vue"),
      meta: {
        title: "Cert generator",
        hideTitle: true,
      },
    },
    {
      path: "/app/activation",
      name: "TheActivation",
      component: () => import("@/pages/authentication/TheActivationView"),
      meta: {
        title: "Активация аккаунта",
        hideTitle: true,
      },
    },
    {
      path: "/app/diagram/",
      name: "NewDiagram",
      component: () => import("@/pages/diagrams/NewDiagram"),
      meta: {
        title: "Новый процесс",
      },
    },
    {
      path: "/app/plant/",
      name: "NewPlant",
      component: () => import("@/pages/plant-uml/NewPlantUML"),
      meta: {
        title: "Новый UML",
      },
    },
    {
      path: "/app/diagram-group/",
      name: "NewGroup",
      component: () => import("@/pages/diagram-group/NewDiagramGroup"),
      meta: {
        title: "Новая группа",
      },
    },
     {
      path: "/app/diagram-group/:diagramid",
      name: "ExistGroup",
      props: true,
      component: () => import("@/pages/diagram-group/ExistDiagramGroup"),
    },
    {
      path: "/app/team/orgchart",
      name: "NewOrgChart",
      component: () => import("@/pages/org-chart/NewOrgChart"),
      meta: {
        title: "Организационная структура",
      },
    },
    {
      path: "/app/team/persons",
      name: "Persons",
      component: () => import("@/pages/persons/PersonView"),
      meta: {
        title: "Сотрудники",
      },
    },
    {
      path: "/app/team/assignees",
      name: "Assignees",
      component: () => import("@/pages/assignees/AssigneeListView"),
      meta: {
        title: "Роли",
      },
    },
    {
      path: "/app/noaccess/:diagramid",
      props: true,
      name: "noaccess",
      component: () => import("@/pages/diagrams/NoAccess"),
      meta: {
        title: "Нет доступа",
      },
    },
    {
      path: "/app/diagram/:diagramid",
      name: "existDiagram",
      props: true,
      component: () => import("@/pages/diagrams/ExistDiagram.vue"),
    },
    {
      path: "/app/diagram/as-is-to-be/:diagramId",
      name: "as-is-to-be",
      meta: {
        title: "Сравнение AS-IS и TO-BE",
      },
      props: true,
      component: () => import("@/pages/diagrams/AsIsDiffView.vue"),
    },
    {
      path: "/app/diagram/embedded/card/:diagramId",
      name: "embeddedCard",
      props: true,
      component: () => import("@/pages/diagrams/EmbeddedCardView"),
    },
    {
      path: "/app/bpm/:diagramId",
      name: "BPMDiagram",
      props: true,
      component: () => import("@/pages/diagrams/BPMDiagramView.vue"),
    },
    {
      path: "/app/plant/:diagramid",
      name: "existPlant",
      props: true,
      component: () => import("@/pages/plant-uml/ExistPlant"),
    },
    
    {
      path: "/app/team/assignee/:assigneeId",
      name: "singleAssignee",
      props: true,
      component: () => import("@/widgets/assignees/manage-single-assignee.vue"),
      meta: {
        title: "Карточка роли",
      },
    },
    {
      path: "/app/team/orgitem/:orgItemId",
      name: "singleOrItem",
      props: true,
      component: () => import("@/pages/org-chart/SingleOrgItemView"),
      meta: {
        title: "Карточка должности",
      },
    },
    {
      path: "/app/team/asset/:assetId",
      name: "singleOrItem",
      props: true,
      component: () => import("@/pages/assets/SingleAssetView"),
      meta: {
        title: "Карточка элемента архитектуры",
      },
    },
    {
      path: "/app/auth",
      name: "joinTeam",
      props: true,
      component: () => import("@/pages/team/TheJoinTeamView"),
      meta: {
        title: "Присоединиться к команде",
      },
    },
    {
      path: "/app/diagramdiff",
      name: "theDiff",
      props: true,
      component: () => import("@/pages/diagrams/TheDifView"),
      meta: {
        title: "Сравнение версий BPMN",
      },
    },
    {
      path: "/app/plantdiff/:diagramid/:versiona/:versionb",
      name: "theDiff",
      props: true,
      component: () => import("@/pages/plant-uml/PlantDiffView.vue"),
      meta: {
        title: "Сравнение версий PlantUML",
      },
    },
    {
      path: "/app/diagram/:diagramid/version/:version",
      name: "existDiagramAndVersion",
      props: true,
      component: () => import("@/pages/diagrams/ExistDiagramAndVersion"),
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/app/plant/:diagramid/version/:version",
      name: "existPlantAndVersion",
      props: true,
      component: () => import("@/pages/plant-uml/ExistPlantAndVersion"),
      beforeEnter: ifAuthenticated,
    },

    {
      path: "/app/signup",
      name: "signup",
      component: () => import("@/pages/authentication/TheSignUpView"),
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Stormbpmn зарегистрироваться",
        hideTitle: true,
      },
    },
    {
      path: "/app/checkEmail/:email",
      name: "checkEmail",
      props: true,
      component: () => import("@/pages/authentication/TheCheckEmail.vue"),
      beforeEnter: ifNotAuthenticated,
    },

    {
      path: "/app/profile",
      name: "profile",
      component: () => import("@/pages/profile/TheProfileView"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Профиль",
      },
    },
    {
      path: "/app/guests",
      name: "guests",
      component: () => import("@/widgets/guests/manage-guests.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Гости",
      },
    },
    {
      path: "/app/team",
      name: "team",
      component: () => import("@/pages/team/TheTeamView.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Ваша команда",
      },
    },
    {
      path: "/app/team/assets",
      name: "assets",
      component: () => import("@/pages/assets/AssetView.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Элементы архитектуры",
      },
    },
    {
      path: "/app/admin",
      name: "admin",
      component: () => import("@/pages/admin/admin.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Администрирование",
      },
    },
    {
      path: "/app/massiveupload",
      name: "massiveupload",
      component: () => import("@/pages/diagrams/MassiveUploadView"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Массовая загрузка",
      },
    },
    {
      path: "/app/signin",
      name: "signin",
      component: () => import("@/pages/authentication/TheSignInView"),
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Войти",
        hideTitle: true,
      },
    },
    {
      path: "/app/signin-yandex",
      name: "signin-yandex",
      component: () => import("@/pages/authentication/yandex-auth-page.vue"),
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Войти",
        hideTitle: true,
      },
    },
    {
      path: "/app/requestPasswordRecover",
      name: "requestPasswordRecover",
      component: () =>
        import("@/pages/authentication/TheRequesPasswordRecoverView"),
      beforeEnter: ifNotAuthenticated,
      meta: {
        title: "Восстановление пароля Stormbpmn",
        hideTitle: true,
      },
    },
    {
      path: "/app/restorePassword/:email",
      props: true,
      name: "TheRecoverPassword",
      component: () => import("@/pages/authentication/TheRecoverPasswordView"),
      beforeEnter: ifNotAuthenticated,
      hideTitle: true,
    },
    {
      path: "/app/home",
      name: "home",
      component: () => import("@/pages/diagrams/TheDiagramList"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Процессы",
      },
    },
    {
      path: "/app/explorerEmbedded/",
      name: "EmbeddedExplorer",
      component: () => import("@/pages/folders/EmbeddedExplorerView"),
      meta: {
        title: "Встроенный обозреватель",
      },
    },
    {
      path: "/app/billing",
      name: "billing",
      component: () => import("@/pages/payments/TheBillingView"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Оплата",
      },
    },
    {
      path: "/app/upgrade",
      name: "upgrage",
      component: () => import("@/pages/payments/TheUpgradeView"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Улучшение подписки",
      },
    },
    {
      path: "/app/generateInvoice",
      name: "generateInvoice",
      component: () => import("@/widgets/payments/generate-invoice.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Генерация счета",
      },
    },
    {
      path: "/app/camundaServers/",
      name: "camundaServers",
      component: () => import("@/pages/camunda/CamundaTableView"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Серверы Camunda",
      },
    },
    {
      path: "/app/camunda/dashboard",
      name: "camundaDashboard",
      component: () => import("@/pages/camunda/camunda-operate/Home"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Управление серверами Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/camunda-operate/definition/:definitionId",
      name: "definition",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/DefinitionDetailView"),
      props: true,
      meta: {
        title: "Определение процесса Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/stats",
      name: "camundaStats",
      component: () => import("@/pages/camunda/camunda-operate/Home"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Статистика Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/stream",
      name: "camundaStream",
      component: () => import("@/pages/camunda/camunda-operate/StreamView"),
      beforeEnter: ifAuthenticated,
      meta: {
        title: "Live Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/processdetail/:processInstanceId",
      name: "processdetail",
      props: true,
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/DetailProcessView.vue"),
      meta: {
        title: "Детали процесса",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/incidents",
      name: "incident",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/IncidentView.vue"),
      meta: {
        title: "Инциденты Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/deployments",
      name: "deployments",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/DeployTableView.vue"),
      meta: {
        title: "Deployments Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/batches",
      name: "batches",
      beforeEnter: ifAuthenticated,
      component: () => import("@/pages/camunda/camunda-operate/BatchView.vue"),
      meta: {
        title: "Массовые операции Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/history",
      name: "history",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/HistoryView.vue"),
      meta: {
        title: "История Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/migration",
      name: "migration",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/ComplexMigrationView.vue"),
      meta: {
        title: "Миграция Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/variablebatch",
      name: "variableEdit",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/VariableBatchModifyView.vue"),
      meta: {
        title: "Массовое изменение переменных Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/camunda/modificationbatch",
      name: "variableEdit",
      beforeEnter: ifAuthenticated,
      component: () =>
        import("@/pages/camunda/camunda-operate/BatchModificationView.vue"),
      meta: {
        title: "Модификация Camunda",
        hideTitle: true,
      },
    },
    {
      path: "/app/search",
      name: "search",
      component: () => import("@/pages/search/SearchPageView.vue"),
      meta: {
        title: "Поиск процессов Stormbpmn",
        hideTitle: true,
      },
    },
    {
      path: "/marketing/",
      name: "Marketing",
      component: () => import("@/pages/marketing/MainLanding.vue"),
      meta: {
        title: "Регулярные вебинары про практику бизнес-процессов",
        hideTitle: true,
      },
    },
    {
      path: "/marketing/pma",
      name: "PMA",
      component: () => import("@/pages/marketing/PMA.vue"),
      meta: {
        title: "Публичное соглашение о моделировании",
        hideTitle: true,
      },
    },
    {
      path: "/marketing/check-your-diagram",
      name: "PMA",
      component: () => import("@/pages/marketing/CheckYourDiagram.vue"),
      meta: {
        title: "Проверка ваших диаграмм в BPMN",
        hideTitle: true,
      },
    },
    {
      path: "/bpmn/free-course",
      name: "Marketing",
      component: () => import("@/pages/marketing/BPMNCourse.vue"),
      meta: {
        title: "Бесплатный онлайн-курс по базовому* BPMN",
        hideTitle: true,
      },
    },
    {
      path: "/hiring/",
      name: "hiring",
      component: () => import("@/pages/hr/WeAreHiring.vue"),
      meta: {
        title: "Наши вакансии",
        hideTitle: true,
      },
    },
  ],
});

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// ...

export default router;
