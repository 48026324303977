import Vue from 'vue'
import App from './app/App.vue'
import router from '@/app/router'
import {store} from '@/app/store'
import BootstrapVue from 'bootstrap-vue'
import VueGtm from "vue-gtm";
import * as Sentry from "@sentry/vue";

import DadataSuggestions from "vue-dadata-suggestions";
Vue.use(DadataSuggestions, {
    token: "026f163e1c27c7d8fe8626857a318db1f97cc4cd",
    type: "PARTY"
  });

import Multiselect from 'vue-multiselect'
import '../src/app/assets/css/tooltip.css';
import '../src/app/assets/css/font-awesome.css';
import VueTagsInput from '@johmun/vue-tags-input';
import fullscreen from 'vue-fullscreen'
import VueMeta from 'vue-meta'
import VueYandexMetrika from "vue-yandex-metrika";
import VuePageTitle from 'vue-page-title'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faDoorClosed,
    faAlignCenter,
    faArrowsAlt,
    faArrowsAltH,
    faCheck,
    faCheckCircle,
    faCodeBranch,
    faLayerGroup,
    faCogs,
    faComment,
    faComments,
    faCopy,
    faCreditCard,
    faDownload,
    faEdit,
    faEnvelopeOpen,
    faExternalLinkAlt,
    faEye,
    faFile,
    faFileAlt,
    faFileUpload,
    faFill,
    faQuestionCircle,
    faFolder,
    faFolderOpen,
    faFolderMinus,
    faInfo,
    faLink,
    faClipboard,
    faLock,
    faMapMarkedAlt,
    faMailBulk,
    faPlus,
    faProjectDiagram,
    faRedo,
    faRulerHorizontal,
    faSave,
    faSearch,
    faSearchLocation,
    faSearchMinus,
    faSearchPlus,
    faFileImport,
    faShare,
    faStar,
    faThumbsUp,
    faExclamationCircle,
    faTimes,
    faTimesCircle,
    faRocket,
    faTrash,
    faTrashRestore,
    faUndo,
    faCubes,
    faUser,
    faArrowDown,
    faClock,
    faClipboardList,
    faForward,
    faArrowLeft,
    faArrowRight,
    faUsers,
    faTh,
    faTable,
    faBan,
    faListOl,
    faArrowUp,
    faTrophy,
    faEllipsisV,
    faMoneyBill,
    faBars,
    faNotesMedical,
    faPencilAlt,
    faServer,
    faTags,
    faUnlink,
    faDatabase,
    faUserSecret,
    faBuilding,
    faQuestion
} from '@fortawesome/free-solid-svg-icons'
import {faJira} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
library.add([ faDoorClosed, faServer,faUnlink, faExclamationCircle, faDatabase, faBuilding, faCubes, faMailBulk,  faUserSecret, faLayerGroup, faArrowLeft, faArrowRight, faClipboard, faPencilAlt, faFileAlt, faNotesMedical, faTags, faArrowDown,  faArrowUp, faMoneyBill,faListOl,  faBan, faTrophy, faFileImport, faArrowUp, faQuestionCircle, faClock, faLock, faFolderMinus, faForward, faTh,faTable, faJira, faClipboardList, faEllipsisV, faBars, faComments, faQuestion, faInfo, faRulerHorizontal, faCopy,faRocket, faFill, faAlignCenter, faCheck, faProjectDiagram, faMapMarkedAlt, faFolderOpen, faSave, faEdit, faShare, faSearchPlus, faSearchMinus, faUsers, faSearchLocation, faDownload, faCreditCard, faFile, faFileUpload, faCheckCircle, faTimesCircle, faCodeBranch, faTrash, faFolder, faFileAlt, faStar, faThumbsUp, faArrowsAltH, faUndo, faRedo, faArrowsAlt, faCogs, faSearch, faExternalLinkAlt, faLink, faTrashRestore, faEye, faComment, faPlus, faUser, faEnvelopeOpen, faTimes]);
Vue.component('font-awesome-icon', FontAwesomeIcon);
import Vuelidate from 'vuelidate'
import VueMarkdown from 'vue-markdown'
import "moment-timezone";
import i18n from '@/app/i18n'
Vue.component('vueInternetChecker', require('vue-internet-checker'));
Vue.component('multiselect', Multiselect)
Vue.component('vue-tags-input', VueTagsInput);
import VueConfetti from 'vue-confetti'
Vue.use(VueConfetti)
Vue.use(fullscreen)
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor )
import VueTour from 'vue-tour'


require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.prototype.showComments = window.showComments;
import FlagIcon from 'vue-flag-icon';

Vue.use(FlagIcon);

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

import VueImg from 'v-img';
const vueImgConfig = {
    // Use `alt` attribute as gallery slide title
    altAsTitle: false,
    // Display 'download' button near 'close' that opens source image in new tab
    sourceButton: true,
    // Event listener to open gallery will be applied to <img> element
    openOn: 'click',
    // Show thumbnails for all groups with more than 1 image
    thumbnails: false,
  }
Vue.use(VueImg,  vueImgConfig);


import {DraggableTree} from 'vue-draggable-nested-tree'

Vue.use(DraggableTree);

Vue.config.productionTip = false;




Vue.use(VueYandexMetrika, {
    id: 51783677,
    router: router,
    env: process.env.NODE_ENV,
    debug: true
});




Vue.use(VuePageTitle, {router})



Sentry.init({
    Vue,
    dsn: "https://786947a48e2dd49ea6ca06fb90b8e956@sentry.stormbpmn.com/4",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [ window.location.origin,
            "localhost",
            "https://stormbpmn.com/api/*",]
      }),
      Sentry.browserProfilingIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', /^\/(?!\/)/],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
  });

Vue.use(Vuelidate);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.component('vue-markdown', VueMarkdown);
const moment = require('moment');
Vue.prototype.$momenttrue = moment;


Vue.prototype.$notify = function(object) {
    
   if (object.type == "error") {
    this.$bvToast.toast(object.text.message, {
        title: object.title,
        autoHideDelay: 5000,
        solid: true,
        toaster: 'b-toaster-bottom-center',
        variant: "danger",
})}   
    if (object.type == "success") {

    this.$bvToast.toast(object.text ? object.text : object.title, {
        title: object.title,
        autoHideDelay: 5000,
        solid: true,
        toaster: 'b-toaster-bottom-center',
        variant: "success",
   } )

   

} 

}

import { ClientTable } from 'vue-tables-2';
Vue.use(ClientTable);
Vue.filter("moment", function (value, format) {
    if (value === null || value === undefined || format === undefined) {
        return ''
    }
    moment.locale('ru')
    let a = moment.tz(value, "Europe/Moscow");
    let userTz = moment.tz.guess();
    let b = moment.tz(a, userTz);
    if (format === 'from') {
        return moment(b).fromNow()
    }
    return moment(b).format(format)
});


import VueCodemirror from 'vue-codemirror'
Vue.use(VueCodemirror)
import 'codemirror/lib/codemirror.css'

Vue.mixin({
    methods: {
        errorInfo(title, description, redirectUrl) {
            this.$bvToast.toast(description, {
                title: title,
                autoHideDelay: 5000,
                solid: true,
                toaster: 'b-toaster-bottom-center',
                variant: "danger",
                href:redirectUrl,
                
            })
        },
        successInfo(title1, description1) {
            
            this.$bvToast.toast(description1, {
                title: title1,
                autoHideDelay: 1000,
                toaster: 'b-toaster-bottom-center',
                solid: true,
                variant: "success"
            })
        }
    }
})

Vue.config.productionTip = false
Vue.use(VueGtm, {
    id: "GTM-NLPQHR3", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]

    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });


var vm =  new Vue({
            router,
            store,
            i18n,
            render: h => h(App)
        }).$mount('#app');
global.vm = vm;


